//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { STable, Ellipsis, XCard } from '@/components'
import { flowableTodoTaskPage } from '@/api/modular/flowable/taskTodoManage'
import { flowableCategoryList } from '@/api/modular/flowable/categoryManage'
import moment from 'moment'
import tracking from '../../tracking/tracking'
import taskForm from './taskForm'
export default {
  components: {
    XCard,
    STable,
    Ellipsis,
    tracking,
    taskForm
  },
  data () {
    return {
      // 高級搜索 展開/關閉
      advanced: false,
      // 查詢參數
      queryParam: {},
      // 表頭
      columns: [
        {
          title: '流程名稱',
          dataIndex: 'procIns.name',
          scopedSlots: { customRender: 'procInsName' }
        },
        {
          title: '發起人',
          dataIndex: 'procIns.startUserName'
        },
        {
          title: '發起時間',
          dataIndex: 'procIns.formatStartTime',
          scopedSlots: { customRender: 'formatStartTime' }
        },
        {
          title: '接收時間',
          dataIndex: 'formatCreateTime',
          scopedSlots: { customRender: 'formatCreateTime' }
        },
        {
          title: '當前節點',
          dataIndex: 'name'
        },
        {
          title: '當前操作人',
          dataIndex: 'assigneeInfo'
        },
        {
          title: '優先級',
          dataIndex: 'priority'
        },
        {
          title: '任務期限',
          dataIndex: 'dueDate',
          scopedSlots: { customRender: 'dueDate' }
        }
      ],
      // 加載數據方法 必須為 Promise 對象
      loadData: parameter => {
        const dates = this.queryParam.dates
        if (dates != null) {
          this.queryParam.searchBeginTime = moment(dates[0]).format('YYYY-MM-DD HH:mm:ss')
          this.queryParam.searchEndTime = moment(dates[1]).format('YYYY-MM-DD HH:mm:ss')
          if (dates.length < 1) {
            delete this.queryParam.searchBeginTime
            delete this.queryParam.searchEndTime
          }
        }
        const obj = JSON.parse(JSON.stringify(this.queryParam))
        delete obj.dates
        return flowableTodoTaskPage(Object.assign(parameter, obj)).then((res) => {
          return res.data
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      flowableCategoryListData: [],
      taskFormCardShow: false,
      indexCardShow: true
    }
  },
  created () {
    this.flowableCategoryList()
    this.columns.push({
      title: '操作',
      width: '150px',
      dataIndex: 'action',
      scopedSlots: { customRender: 'action' }
    })
  },
  methods: {
    moment,
    /**
     * 獲取流程類型
     */
    flowableCategoryList () {
      flowableCategoryList().then((res) => {
        this.flowableCategoryListData = res.data
      })
    },
    handFormOpen (record) {
      this.taskFormCardShow = true
      this.indexCardShow = false
      this.$refs.taskForm.open(record)
    },
    handFormOk () {
      this.handFormClose()
    },
    handFormClose () {
      this.$refs.table.refresh()
      this.taskFormCardShow = false
      this.indexCardShow = true
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }

}
